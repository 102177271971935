import { useState } from 'react';
import API from '../api/axiosConfig';

const useBookAppointment = () => {
    const [bookingError, setBookingError] = useState(null);
    const [isBooking, setIsBooking] = useState(false);

    const bookAppointment = async (_pos, date, time, _at, isNewPatient, isReschedule, isPlanned, AptData) => {
        setIsBooking(true);
        setBookingError(null);

        const appointmentDateTime = `${date} ${time}`;
        try {
            // Prepare the payload for the backend API
            const payload = {
                aptdatetime: appointmentDateTime,
                isnewpatient: isNewPatient ? "true" : "false",
                isReschedule: isReschedule,
                isPlanned: isPlanned,
                _pos: _pos
            };

            if (isReschedule) {
                // Call reschedule API
                console.log("Rescheduling appointment:", payload);
                const response = await API.post('/appointments/web/change', { reschedule: AptData, AptDateTime: appointmentDateTime });
                return response.data;
            } else {
                // Call booking API
                if (!isPlanned) {
                    payload._at = _at;
                } else {
                    payload.AptData = AptData;
                }
                console.log("Booking appointment:", payload);
                const response = await API.post('/appointments/web/book', payload);
                return response.data;
            }

        } catch (error) {
            let description = "There was an error processing your request. Please try again.";
            if (error.response?.data?.error) {
                description = error.response.data.error;
            }
            setBookingError(description);
            throw error;
        } finally {
            setIsBooking(false);
        }
    };

    return { bookAppointment, isBooking, bookingError };
};

export default useBookAppointment;
