import React, { useState } from 'react';
import { VStack, Divider, Button, Box, Heading, HStack, Spacer, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import useAppointments from '../hooks/useAppointments';
import AppointmentsList from '../components/AppointmentsList';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorAlert from '../components/ErrorAlert';

const Dashboard = () => {
    const navigate = useNavigate();
    const { appointments, isLoading, error, fetchAppointments } = useAppointments();
    const [errorAlertVisible, setErrorAlertVisible] = useState(true);

    const handleBookingClick = () => {
        // Clear local storage items
        sessionStorage.removeItem('selectedType');
        sessionStorage.removeItem('slots');
        sessionStorage.removeItem('selectedAppointment');
        // Navigate to the book appointment page
        navigate('/book-appointment'); // Updated to the correct route
    };

    return (
        <Box p={6} mx="auto">
            {isLoading ? (
                <LoadingSpinner />
            ) : error && errorAlertVisible ? (
                <ErrorAlert
                    title="Error"
                    description={error}
                    onClose={() => setErrorAlertVisible(false)}
                />
            ) : (
                <VStack spacing={8} align="stretch">
    <HStack>
        <Heading size="lg">Dashboard</Heading>
        <Spacer />
        <Button colorScheme="blue" onClick={handleBookingClick}>
            <Icon as={FaCalendarAlt} boxSize={5} color="white" mr={2} />
            <Text>Book Appointment</Text>
        </Button>
    </HStack>
    <Divider />
    
    {/* New Section for Planned Appointments */}
    {appointments.planned && appointments.planned.length > 0 && (
        <>
            <AppointmentsList 
                appointments={appointments.planned} 
                title="Planned Appointments" 
                onAppointmentChange={fetchAppointments} 
                isPastAppointment={false} 
                isPlanned={true} 
            />
            <Divider />
        </>
    )}
    
    {/* Upcoming Appointments */}
    {/*appointments.upcoming && appointments.upcoming.length > 0 && (*/
        <>
            <AppointmentsList 
                appointments={appointments.upcoming} 
                title="Upcoming Appointments" 
                onAppointmentChange={fetchAppointments} 
                isPastAppointment={false} 
            />
            <Divider />
        </>
    /*)*/}
    
    {/* Past Appointments */}
    {appointments.past && appointments.past.length > 0 && (
        <>
            <AppointmentsList 
                appointments={appointments.past} 
                title="Past Appointments" 
                isPastAppointment={true} 
            />
        </>
    )}
</VStack>

            )}
        </Box>
    );
};

export default Dashboard;
