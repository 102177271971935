import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, VStack, Heading, Center, HStack, IconButton, useToast } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import useAppointmentTypes from '../hooks/useAppointmentTypes';
import useBookAppointment from '../hooks/useBookAppointment';
import AppointmentsGroup from '../components/AppointmentsGroup';
import AppointmentSlots from '../components/AppointmentSlots';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorAlert from '../components/ErrorAlert';
import API from '../api/axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebAppContext } from '../contexts/WebAppContext';
import "../styles/pages/BookAppointment.css"; // Import the CSS file

const BookAppointment = () => {
    const { appointmentTypes, isLoading, error } = useAppointmentTypes();
    const [selectedType, setSelectedType] = useState(() => {
        const savedType = sessionStorage.getItem('selectedType');
        return savedType ? savedType : null;
    });
    const [slots, setSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slotsError, setSlotsError] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 13)));
    const { patientType } = useContext(WebAppContext);

    const { bookAppointment, isBooking, bookingError } = useBookAppointment();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const selectedAppointment = location.state?.selectedAppointment;

    const isReschedule = Boolean(selectedAppointment);
    const isPlanned = selectedAppointment && selectedAppointment.AptStatus === "Planned";
    const isScheduledPlanned = selectedAppointment?.IsScheduledPlanned;  // Checking if the appointment is a scheduled planned appointment

    useEffect(() => {
        const savedType = sessionStorage.getItem('selectedType');
        const savedSlots = sessionStorage.getItem('slots');

        if (selectedAppointment) {
            if (isPlanned || isScheduledPlanned) {
                // Directly fetch slots for planned or scheduled planned appointments
                setSlots([]);
                setLoadingSlots(true);
                fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, startDate.toISOString().split('T')[0]);
            } else {
                // For regular appointments, fetch based on selected type
                setSelectedType(savedType ? savedType : null);
                setSlots([]);
                setLoadingSlots(true);
                if (savedType) {
                    fetchSlots(savedType, startDate.toISOString().split('T')[0]);
                }
            }
        } else {
            if (savedType) {
                setSelectedType(savedType);
                setSlots(savedSlots ? JSON.parse(savedSlots) : []);
                setLoadingSlots(true);
                fetchSlots(savedType, startDate.toISOString().split('T')[0]);
            }
            if (savedSlots) {
                setSlots(savedSlots);
            }
        }
    }, [isPlanned, isScheduledPlanned, location, selectedAppointment, startDate]);

    // Fetch slots for planned or scheduled planned appointments
    const fetchSlotsForPlannedAppointment = async (isPlanned, _at, dateStart) => {
        const numberOfDays = 14;
        try {
            const response = await API.get(`/appointments/web/open/slots`, {
                params: {
                    datestart: dateStart,
                    numberofdays: numberOfDays,
                    _at,  // Ensure _at is correct for planned or scheduled planned appointments
                    isPlanned,  // Use isPlanned to differentiate
                }
            });
            setSlots(response.data);
            sessionStorage.setItem('slots', JSON.stringify(response.data));
        } catch (err) {
            setSlotsError('Failed to load slots. Please try again.');
            sessionStorage.removeItem('slots');
        } finally {
            setLoadingSlots(false);
        }
    };

    // Fetch slots for regular appointments
    const fetchSlots = async (type, dateStart) => {
        const numberOfDays = 14;
        try {
            const response = await API.get(`/appointments/web/open/slots`, {
                params: {
                    datestart: dateStart,
                    numberofdays: numberOfDays,
                    _at: type  // Use the selected type for regular appointments
                }
            });
            setSlots(response.data);
            sessionStorage.setItem('slots', JSON.stringify(response.data));
        } catch (err) {
            setSlotsError('Failed to load slots. Please try again.');
            sessionStorage.removeItem('slots');
        } finally {
            setLoadingSlots(false);
        }
    };

    const handleSelectType = ({ token }) => {
        setSelectedType(token);
        sessionStorage.setItem('selectedType', token);
        setSlots([]);
        setLoadingSlots(true);
        setSlotsError(null);
        const dateStart = startDate.toISOString().split('T')[0];
        fetchSlots(token, dateStart);
    };

    const handlePrevDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() - 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() - 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        if (isPlanned || isScheduledPlanned) {
            fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, newStartDate.toISOString().split('T')[0]);
        } else {
            fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
        }
    };

    const handleNextDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() + 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        if (isPlanned || isScheduledPlanned) {
            fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, newStartDate.toISOString().split('T')[0]);
        } else {
            fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
        }
    };

    const handleConfirmBooking = async (_pos, date, time) => {
        // If a booking is already in progress, don't start a new one
        if (isBooking) return;
    
        console.log("Starting the booking or rescheduling process");
    
        const isNewPatient = patientType === 'new';
        let appointmentType = selectedType;
        let rescheduleSuccess = false;
        const AptData = selectedAppointment?.AptData || undefined;
    
        try {
            // 1. Planned Appointment (New Booking)
            const isPlanned = selectedAppointment?.AptStatus === "Planned" && !selectedAppointment?.IsScheduledPlanned;
    
            // 2. Scheduled Planned Appointment (Reschedule)
            const isScheduledPlanned = selectedAppointment?.IsScheduledPlanned;
    
            // 3. Upcoming Appointment (Reschedule)
            const isUpcoming = !isPlanned && !isScheduledPlanned && selectedAppointment?.AptStatus === "Scheduled";
    
            // 4. New Regular Appointment (New Booking)
            const isNewBooking = !selectedAppointment && !isPlanned && !isScheduledPlanned;
    
            // Logging the identified type for debugging
            console.log({
                isPlanned,
                isScheduledPlanned,
                isUpcoming,
                isNewBooking,
                isNewPatient,
                appointmentType,
            });
    
            // Case 1: Planned Appointment (New Booking)
            if (isPlanned) {
                console.log("Handling Planned Appointment (New Booking):");
                await bookAppointment(_pos, date, time, appointmentType, isNewPatient, false, true, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Booking Successful", "Your planned appointment has been booked successfully.");
            }
    
            // Case 2: Scheduled Planned Appointment (Reschedule)
            else if (isScheduledPlanned) {
                console.log("Handling Scheduled Planned Appointment (Reschedule):");
                await bookAppointment(_pos, date, time, appointmentType, isNewPatient, true, true, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Rescheduling Successful", "Your scheduled planned appointment has been rescheduled successfully.");
            }
    
            // Case 3: Upcoming Appointment (Reschedule)
            else if (isUpcoming) {
                console.log("Handling Upcoming Appointment (Reschedule):");
                await bookAppointment(_pos, date, time, appointmentType, isNewPatient, true, false, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Rescheduling Successful", "Your upcoming appointment has been rescheduled successfully.");
            }
    
            // Case 4: New Regular Appointment (New Booking)
            else if (isNewBooking) {
                console.log("Handling New Regular Appointment (New Booking):");
                await bookAppointment(_pos, date, time, appointmentType, isNewPatient, false, false, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Booking Successful", "Your new appointment has been booked successfully.");
            }
    
            // Navigate after the operation
            navigate('/thank-you', { state: { rescheduleSuccess } });
        } catch (error) {
            console.log("Error during booking or rescheduling process:", error);
            handleToast(false, "Booking Failed", bookingError || "There was an error booking your appointment. Please try again.");
        }
    };    
    
    const handleToast = (success, title, description) => {
        toast({
            title: success ? title : `${title} Failed`,
            description: success ? description : `There was an issue: ${description}`,
            status: success ? "success" : "error",
            duration: 5000,
            isClosable: true,
        });
    
        // Log the toast message for debugging
        console.log("Toast Notification:", {
            success,
            title,
            description,
        });
    };
    
    // Formatting the date for display
    const formattedStartDate = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    // Show loading spinner or error alert if needed
    if (isLoading) return <LoadingSpinner />;
    if (error) return <ErrorAlert title="Error" description="Failed to load appointment types. Please try again." onClose={() => {}} />;

    return (
        <Box className="book-appointment-container">
            {slotsError && <ErrorAlert title="Error" description={slotsError} onClose={() => setSlotsError(null)} />}

            {/* If no type is selected and it's not a planned appointment, show the type selection */}
            {!selectedType && !isPlanned && !isScheduledPlanned && (
                <Center className="select-type-heading">
                    <Heading as="h3" size="lg" fontWeight="bold">What type of care are you looking for?</Heading>
                </Center>
            )}

            {!selectedType && !isPlanned && !isScheduledPlanned ? (
                <VStack className="select-type-list">
                    {appointmentTypes.primary && appointmentTypes.primary.map(({ typeName, token }, index) => (
                        <Box
                            key={index}
                            className="type-box"
                            onClick={() => handleSelectType({ typeName, token })}
                        >
                            <Text className="type-text">{typeName}</Text>
                        </Box>
                    ))}
                    {appointmentTypes.consults && appointmentTypes.consults.length > 0 && (
                        <Box className="consult-box">
                            <Text className="type-text">Consults</Text>
                            <VStack className="consult-list">
                                {appointmentTypes.consults.map(({ typeName, token }, index) => (
                                    <Box
                                        key={index}
                                        className="type-box"
                                        onClick={() => handleSelectType({ typeName, token })}
                                    >
                                        <Text className="type-text">{typeName}</Text>
                                    </Box>
                                ))}
                            </VStack>
                        </Box>
                    )}
                </VStack>
            ) : (
                // If a type is selected or it's a planned appointment, show the slots section
                <Box>
                    {/* Display the appointment type group selection only if not planned or scheduled planned */}
                    {!isPlanned && !isScheduledPlanned && (
                        <AppointmentsGroup
                            title="Appointment Types"
                            appointments={[...appointmentTypes.primary, ...appointmentTypes.consults]}
                            onSelect={handleSelectType}
                            selectedType={selectedType}
                            isClickable={!selectedAppointment}
                        />
                    )}

                    <HStack className="slots-header">
                        <Text>{slots.length ? `${slots.length} providers` : 'Loading providers...'}</Text>
                        <HStack>
                            <IconButton
                                icon={<FaChevronLeft />}
                                onClick={handlePrevDates}
                                isDisabled={startDate.toDateString() === new Date().toDateString()}
                                aria-label="Previous dates"
                            />
                            <Text>{`${formattedStartDate} – ${formattedEndDate}`}</Text>
                            <IconButton
                                icon={<FaChevronRight />}
                                onClick={handleNextDates}
                                aria-label="Next dates"
                            />
                        </HStack>
                    </HStack>

                    {/* Show loading spinner if slots are still loading */}
                    {loadingSlots ? (
                        <LoadingSpinner message="Loading slots..." />
                    ) : (
                        <AppointmentSlots
                            slots={slots}
                            error={slotsError}
                            startDate={startDate}
                            onConfirmBooking={handleConfirmBooking}
                            isBooking={isBooking}
                            bookingError={bookingError}
                            selectedAppointment={selectedAppointment}
                            isPlanned={isPlanned}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default BookAppointment;