import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, HStack, Icon, Button, Divider, useDisclosure, Center, Spinner, useToast } from '@chakra-ui/react';
import { FaCalendarAlt } from 'react-icons/fa';
import ConfirmModal from './ConfirmModal';
import API from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/components/AppointmentsList.css'; // Import the external CSS file

const AppointmentsList = ({ appointments, title, onAppointmentChange, isPastAppointment, isPlanned }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [actionType, setActionType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingAppointments, setLoadingAppointments] = useState(false);
    const [fetchAppointmentsOnClose, setFetchAppointmentsOnClose] = useState(false);
    const [cancelThresholdHours, setCancelThresholdHours] = useState(null);
    const [rescheduleThresholdHours, setRescheduleThresholdHours] = useState(null);
    const [loadingThresholds, setLoadingThresholds] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();
    const handleOpenModal = (appointment, type) => {
        setSelectedAppointment(appointment);
        setActionType(type);
        onOpen();
    };

    const handleConfirm = async () => {
        if (actionType === 'reschedule') {
            // Handle reschedule: Navigate to the BookAppointment page
            sessionStorage.setItem('selectedType', selectedAppointment.AptData); // Store encrypted appointment data

            const appointmentTypeNum = selectedAppointment.AppointmentTypeNum && selectedAppointment.AppointmentTypeNum !== 0
                ? selectedAppointment.AppointmentTypeNum
                : null;

            navigate('/book-appointment', {
                state: {
                    selectedAppointment: {
                        ...selectedAppointment,
                        AppointmentTypeNum: appointmentTypeNum, // Pass the appointment type number
                    },
                    plannedAptNum: selectedAppointment.PlannedAptNum,
                },
            });
        } else if (actionType === 'cancel') {
            // Handle cancel: Call the cancellation API
            setIsLoading(true);
            try {
                const response = await API.post('/appointments/web/change', {
                    cancel: selectedAppointment.AptData, // Passing encrypted data
                });

                if (response.data.status === 'success') {
                    handleToast(true, "Cancellation Successful", "Your appointment has been canceled successfully.");
                    setFetchAppointmentsOnClose(true); // Set flag to fetch appointments on modal close
                    onClose(); // Close the modal after successful cancellation
                } else {
                    handleToast(false, "Cancellation Failed", "There was an issue canceling your appointment. Please try again.");
                }
            } catch (error) {
                console.error('Error cancelling appointment:', error);
                handleToast(false, "Cancellation Failed", "There was an issue canceling your appointment. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleToast = (success, title, description) => {
        toast({
            title: success ? title : `${title} Failed`,
            description: success ? description : `There was an issue: ${description}`,
            status: success ? "success" : "error",
            duration: 5000,
            isClosable: true,
        });
    
        // Log the toast message for debugging
        console.log("Toast Notification:", {
            success,
            title,
            description,
        });
    };

    useEffect(() => {
        const fetchThresholds = async () => {
            try {
                const response = await API.get('/appointments/web/thresholds'); // Fetch threshold values from backend
                if (response.data) {
                    setCancelThresholdHours(response.data.cancelThresholdHours);
                    setRescheduleThresholdHours(response.data.rescheduleThresholdHours);
                } else {
                    console.error('Failed to fetch threshold values');
                }
            } catch (error) {
                console.error('Error fetching threshold values:', error);
            } finally {
                setLoadingThresholds(false);
            }
        };

        fetchThresholds();
    }, []);

    useEffect(() => {
        const fetchUpdatedAppointments = async () => {
            setLoadingAppointments(true);
            await onAppointmentChange(); // Fetch the updated appointments list
            setLoadingAppointments(false);
        };

        if (!isOpen && fetchAppointmentsOnClose) {
            fetchUpdatedAppointments();
            setFetchAppointmentsOnClose(false); // Reset the flag
        }

        if (!isOpen && !fetchAppointmentsOnClose) {
            setSelectedAppointment(null); // Clear the selected appointment only when not fetching
        }
    }, [isOpen, fetchAppointmentsOnClose, onAppointmentChange]);

    const isWithinThreshold = (appointmentDateTime, thresholdHours) => {
        const now = new Date();
        const appointmentTime = new Date(appointmentDateTime);
        const timeDifference = (appointmentTime - now) / (1000 * 60 * 60); // Convert time difference to hours
        return timeDifference <= thresholdHours;
    };

    return (
        <Box>
            <Heading size="md" mb={4}>{title}</Heading>
            {loadingThresholds ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading thresholds...</Text>
                </Center>
            ) : loadingAppointments ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading appointments...</Text>
                </Center>
            ) : (
                <VStack align="stretch" spacing={4}>
                    {appointments.length ? appointments.map((appointment, index) => {
                        const isPast = appointment.AptDateTime ? new Date(appointment.AptDateTime) < new Date() : false;
                        const disableCancel = cancelThresholdHours !== null && appointment.AptDateTime && isWithinThreshold(appointment.AptDateTime, cancelThresholdHours);
                        const disableReschedule = rescheduleThresholdHours !== null && appointment.AptDateTime && isWithinThreshold(appointment.AptDateTime, rescheduleThresholdHours);

                        return (
                            <Box
                                key={index}
                                className={`appointment-card ${isPast ? 'past' : 'upcoming'}`}
                            >
                                <HStack mb={2} alignItems="center">
                                    <Icon as={FaCalendarAlt} className="icon" />
                                    <Text fontSize="lg" fontWeight="bold">{appointment.AppointmentTitle}</Text>
                                </HStack>
                                <Divider mb={2} />
                                
                                {/* Handling Planned Appointments */}
                                {isPlanned ? (
                        <>
                            {/* Display the procedure description */}
                            <Text><strong>Procedures Planned:</strong> {appointment.ProcDescript || "N/A"}</Text>
                            {/* Display any additional notes if available */}
                            {appointment.Note && <Text><strong>Notes:</strong> {appointment.Note}</Text>}
                            {/* Display the priority of the planned appointment */}
                            <Text><strong>Priority:</strong> {appointment.Priority || "Normal"}</Text>
                            <Text><strong>Provider:</strong> {appointment.ProvFullName}</Text>
                            <Button
                                colorScheme="green"
                                size="sm"
                                mt={2}
                                onClick={() => navigate('/book-appointment', {
                                    state: {
                                        selectedAppointment: appointment,
                                        plannedAptNum: appointment.PlannedAptNum,
                                        isPlanned: true // Pass this as true to the booking page
                                    }
                                })}
                            >
                                Book
                            </Button>
                        </>
                    ) : (
                        <>
                            {/* Handling Scheduled or Past Appointments */}
                            {appointment.AptDateTime && (
                                <>
                                    <Text><strong>Date:</strong> {new Date(appointment.AptDateTime).toLocaleDateString()}</Text>
                                    <Text><strong>Time:</strong> {new Date(appointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                </>
                            )}
                            <Text><strong>Appointment Status:</strong> {appointment.AptStatus}</Text>
                            <HStack className="button-group" spacing={2}>
                                {isPastAppointment ? (
                                    <Text color="gray.500">Past Appointment</Text>
                                ) : (
                                    <>
                                        <Button 
                                            colorScheme="red" 
                                            size="sm" 
                                            onClick={() => handleOpenModal(appointment, 'cancel')} 
                                            isDisabled={disableCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            colorScheme="blue" 
                                            size="sm" 
                                            onClick={() => handleOpenModal(appointment, 'reschedule')} 
                                            isDisabled={disableReschedule}
                                        >
                                            Reschedule
                                        </Button>
                                    </>
                                )}
                            </HStack>
                        </>
                    )}
                            </Box>
                        );
                    }) : <Text>No {title.toLowerCase()}.</Text>}
                </VStack>
            )}
            {selectedAppointment && (
                <ConfirmModal
                    isOpen={isOpen}
                    onClose={() => {
                        setFetchAppointmentsOnClose(false); // Ensure no fetch occurs on close
                        onClose();
                    }}
                    onConfirm={handleConfirm}
                    appointment={selectedAppointment}
                    actionType={actionType}
                    isLoading={isLoading}
                />
            )}
        </Box>
    );
};

export default AppointmentsList;
