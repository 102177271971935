import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';

// Create the toast instance
const { toast } = createStandaloneToast();

// Create axios instance with a default base URL and headers
const API = axios.create({
  baseURL:  `https://api.bookdentist.pro/api/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Function to sanitize UUID
const sanitizeUUID = (uuid) => {
  const validUUID = /^[a-zA-Z0-9-]+$/;
  return validUUID.test(uuid) ? uuid : null;
};

export const setCsrfToken = (token) => {
  API.defaults.headers['x-csrf-token'] = token;
};

// Response interceptor to handle 401 errors
API.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Show toast message
      toast({
        title: 'Session expired.',
        description: "Let's get your information again.",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      
      // Get the UUID before clearing session storage
      const uuid = sessionStorage.getItem('UUID');
      
      // Clear session storage
      sessionStorage.clear();
      
      // Set the UUID back to session storage
      if (uuid) {
        sessionStorage.setItem('UUID', uuid);
      }

      // Sanitize the UUID before using it in the redirection
      const sanitizedUUID = sanitizeUUID(uuid);
      if (sanitizedUUID) {
        // Redirect to the specific page after a slight delay to ensure the toast is visible
        setTimeout(() => {
          window.location.href = `/${sanitizedUUID}`;
        }, 1000); // Adjust delay as needed
      } else {
        console.error('Invalid UUID format');
      }
    }
    return Promise.reject(error);
  }
);

// Handle the temporary token generation
/*export const generateTempToken = (uuid) => {
  const sanitizedUUID = sanitizeUUID(uuid);
  if (sanitizedUUID) {
    return API.post('/web/auth/generate-temp-token', { webAppUUID: sanitizedUUID });
  } else {
    return Promise.reject(new Error('Invalid UUID format'));
  }
};*/


// Then, when making requests:
export const generateTempToken = (uuid) => {
  const sanitizedUUID = sanitizeUUID(uuid);
  const csrfToken =  API.defaults.headers['x-csrf-token']; // Ensure you have this token stored
  console.log("stunt", csrfToken);
  if (sanitizedUUID) {
    return API.post('/web/auth/generate-temp-token', 
      { webAppUUID: sanitizedUUID },
      {
        headers: {
          'x-csrf-token': csrfToken, // Include CSRF token here
        },
        withCredentials: true, // Send cookies with this request
      }
    );
  } else {
    return Promise.reject(new Error('Invalid UUID format'));
  }
};


export default API;
